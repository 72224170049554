
import { Vue, Component, mixins } from 'nuxt-property-decorator'
import Categories from '~/mixins/categories'
import { findByValue } from '~/scripts/array-of-objects'
import { getCategoryIcon } from '~/services/public/Listing'

@Component
export default class ActivePrelistingComponent extends mixins(Categories) {
  multipleShow: boolean = false
  listings: Record<string, any>[] = []
  showActivePrelistings: boolean = true
  activePrelistingLimit: number = 2
  activePrelistingCategory: number = 0

  async mounted() {
    if (!this.$cookies.get('active_prelistings_banner')) {
      try {
        const url =
          this.$config.v4Host +
          '/ng/getactiveprelistings?load_count=' +
          this.activePrelistingLimit
        const result = await this.$axios.$get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          withCredentials: true
        })
        let listings = result.listings
        if (this.activePrelistingCategory) {
          listings = listings.filter((listing) => {
            return listing.category_id == this.activePrelistingCategory
          })
        }
        this.listings = listings
      } catch (e) {}
    }
  }

  bannerClose() {
    const resDate = new Date()
    resDate.setDate(resDate.getDate() + 14)
    this.$cookies.set('active_prelistings_banner', true, {
      expires: resDate,
      path: '/',
      secure: true,
    })

    this.showActivePrelistings = false
  }

  getTitle(listing: Record<string, any>) {
    const category = this.Categories_getCategoryByV4Id(listing.category_id)
    let title = category ? category!.name + ' delivery' : 'Delivery'

    if (listing.pickup && listing.pickup.town) {
      title += ' from ' + listing.pickup.town
    }
    if (listing.delivery && listing.delivery.town) {
      title += ' to ' + listing.delivery.town
    }

    return title
  }

  getIcon(listing: Record<string, any>) {
    const category = this.Categories_getCategoryByV4Id(listing.category_id)
    return getCategoryIcon(category?.icon)
  }

  getItems(listing: Record<string, any>) {
    if (listing.items.length > 0) {
      let items = ''
      listing.items.every((ItemValue, itemIndex) => {
        if (items.length > 50) {
          items += '...'
          return false
        }
        if (itemIndex != 0) {
          items += ', '
        }
        items += ItemValue.qty + 'x' + ' ' + ItemValue.name

        return true
      })

      return items
    }

    return ''
  }
}
